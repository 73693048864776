<template>
	<!-- Element LAyout -->
	<el-row class="container">
		<el-col
			:span="24"
			:md="12"
			class="hidden-sm-and-down left-container">
			<el-image
				:src="require('@/assets/new-order-plus-with-word-white.png')"
				class="text-center cover-image orderplus-icon" />
			<!-- <a
				class="privacy"
				:href="`${baseURL}static/privacy-policy.html`"
				target="__blank">
				นโยบายความเป็นส่วนตัว
			</a> -->
		</el-col>
		<el-col
			:span="24"
			:md="12"
			class="router-view-container"
		>
			<router-view />
		</el-col>
	</el-row>
	<!-- End Layout -->
</template>

<script>
import { defineComponent } from 'vue'
import { baseURL } from '@/config'

export default defineComponent({
	name: 'LayoutLoginRegister',
	setup() {
		return { baseURL }
	},
})
</script>


<style lang="scss" scoped>
.orderplus-icon {
	max-width: 490px;
}
.container {
	height: 100vh;

	.left-container {
		// background-color: rgba(0, 69, 163, 0.3);
		background-image: linear-gradient(rgba(0, 69, 163, 0.3), rgba(0, 69, 163, 0.3)), url('~@/assets/images/login-background.png');
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
	}
	.cover-image {
		width: 50%;
		height: auto;
	}
}
.privacy {
	position: absolute;
	bottom: 1rem;
	right: 1rem;
	text-decoration: underline;
	color: #ffffff;
}
</style>